
document.addEventListener("DOMContentLoaded",
    function() {
        var textareaElementer = document.getElementsByTagName("textarea");

        for (var i = 0; i < textareaElementer.length; i++) {
            if (textareaElementer[i].getAttribute("data-ignorer-autoskaler")) {
                continue;
            }
            let scrollHeight = textareaElementer[i].scrollHeight;
            textareaElementer[i].setAttribute("style", `height:${scrollHeight + 5}px;`);
            textareaElementer[i].addEventListener("input", autoskalerTextArea, false);
        }
    });

function autoskalerTextArea() {
    this.style.height = "auto";
    this.style.height = (this.scrollHeight + 5) + "px";
}